/*
 * Component Description
 */
import * as React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import Course from "../screens/course";
import HomePage from "../screens/home/";

export default function RouterWrapper() {
  return (
    <Router>
      <>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

        {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
        <Switch>
          <Route path={`/course/:courseID`}>
            <CourseWrapper />
          </Route>
          <Route path={`/course`}>
            <CourseWrapper />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </>
    </Router>
  );
}

function Home() {
  return <HomePage />;
}

function CourseWrapper() {
  let { courseID } = useParams<{ courseID?: string }>();

  return <Course courseID={courseID} />;
}

function Users() {
  return <h2>Users</h2>;
}
