/*
 * Component Description
 */
import * as React from "react";

import BackgroundVideo from "./subcomponents/BackgroundVideo";
import Banner from "./subcomponents/Banner";
import Categories from "./subcomponents/Categories";
import { StoreContext as AppStoreContext } from "../../data/stores/AppStore";

import { Menu, Nav } from "../../components/meta/";

import { scrollToTop } from "../../data/helpers";
// import { homeBannerImage } from "../../data/imageNames";

import "./style.scss";

interface Props {}

export default (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);

  React.useEffect(() => {
    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          showMenu: false,
        },
        type: "APP_STORE_TOGGLE_NAVMENU",
      });
    }

    scrollToTop();
  }, []);

  return (
    <>
      <Nav />

      <Menu />

      <BackgroundVideo />

      <main
        className="home-main-cont"
        // style={{
        //   background: `linear-gradient(to bottom, rgba(29, 50, 91, 0.6), rgba(29, 50, 91, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 80%), url(${getPublicImagePath(
        //     homeBannerImage
        //   )})`,
        // }}
      >
        <Banner />

        <Categories />
      </main>
    </>
  );
};
