/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Icon } from "../../../components/common/";

import "./FileTask.scss";

interface Props {
  taskData: Mastery.FileTask;
}

export default ({ taskData }: Props) => {
  const [hasBeenAcknowledged, updateHasBeenAcknowledged] =
    React.useState(false);

  return (
    <section className="file-task-cont">
      <button
        className={cx(
          "file-task-btn file-task-read-button",
          hasBeenAcknowledged && "acknowledged"
        )}
        disabled={hasBeenAcknowledged}
        onClick={() => updateHasBeenAcknowledged(true)}
      >
        <Icon className="file-task-read-button-icon" icon="check" size={32} />

        <span className="file-task-read-button-text">
          {hasBeenAcknowledged ? "Complete" : "Click to Acknowledge Read"}
        </span>
      </button>

      <button className="file-task-btn file-task-download-button">
        <Icon className="file-task-file-button-icon" icon="file" size={40} />

        <div className="file-task-download-button-info">
          <h3 className="file-task-download-button-info-name">
            {taskData.fileName}
          </h3>
          <p className="file-task-download-button-info-desc">
            Click here to Download
          </p>
        </div>

        <Icon
          className="file-task-download-button-icon"
          icon="download"
          size={40}
        />
      </button>
    </section>
  );
};
