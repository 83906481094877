/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { makeCourseTaskDOMID } from "../../../data/helpers";

import FileTask from "./FileTask";
import SurveyTask from "./SurveyTask";
import VideoTask from "./VideoTask";

import "./CourseTask.scss";

interface Props {
  courseID: string;
  taskData: Mastery.FileTask | Mastery.VideoTask | Mastery.SurveyTask;
  taskIdx: number;
}

export default ({ courseID, taskData, taskIdx }: Props) => {
  const taskRef = React.useRef<HTMLElement>(null);

  const [domID] = React.useState(
    makeCourseTaskDOMID({
      courseID,
      taskID: taskData.taskID,
    })
  );
  const [revealTask, updateRevealTask] = React.useState(taskIdx <= 2); // * Auto show first 2 questions
  const [hasBeenObserved, updateHasBeenObserved] = React.useState(taskIdx <= 2);
  const [intObs] = React.useState(
    "IntersectionObserver" in window
      ? new window.IntersectionObserver(
          (entries) => {
            if (!intObs) {
              return;
            }

            if (!revealTask) {
              entries.map((entry) => {
                const shouldReveal =
                  entry.isIntersecting && entry.intersectionRatio >= 0.25;

                if (shouldReveal && !revealTask) {
                  updateRevealTask(shouldReveal);

                  intObs.disconnect();
                }
              });
            }
          },
          {
            root: null,
            rootMargin: "0px",
            threshold: [0.25],
          }
        )
      : null
  );

  React.useEffect(() => {
    if (taskRef.current && !hasBeenObserved) {
      updateHasBeenObserved(true);

      if (intObs) {
        intObs.observe(taskRef.current);
      } else if (!("IntersectionObserver" in window)) {
        updateRevealTask(true);
      }
    }
  }, [taskRef]);

  return (
    <section
      className={cx(
        "course-task-cont",
        `is-${taskData.taskType}`,
        !revealTask && "hide-task"
      )}
      id={domID}
      ref={taskRef}
    >
      {taskData.name && <h2 className="course-task-name">{taskData.name}</h2>}

      {taskData.description && (
        <p className="course-task-desc">{taskData.description}</p>
      )}

      {renderCourseContent(taskData)}
    </section>
  );
};

function renderCourseContent(
  taskData: Mastery.VideoTask | Mastery.SurveyTask | Mastery.FileTask
) {
  switch (taskData.taskType) {
    case "file":
      return <FileTask taskData={taskData} />;
    case "video":
      return <VideoTask taskData={taskData} />;
    case "survey":
      return (
        <SurveyTask
          onValueChange={(answerID: string) => {}}
          taskData={taskData}
        />
      );
    default:
      return null;
  }
}
