/*
 * Component Description
 */
import { Video } from "../../../components/common/";

import "./VideoTask.scss";

interface Props {
  taskData: Mastery.VideoTask;
}

export default ({ taskData }: Props) => {
  if (!taskData || !taskData.videoPath) {
    return null;
  }

  return (
    <section>
      <Video
        className="course-videotask-video"
        poster={taskData.posterImgPath}
        playsInline={true}
        src={taskData.videoPath}
      />
      {/* <div className="course-videotask-video">|-- Video Here --|</div> */}
    </section>
  );
};
