import React from "react";
import * as _ from "lodash";
import cx from "classnames";

import RouterWrapper from "./router/";

import {
  StoreContextProvider,
  StoreContext as AppStoreContext,
} from "./data/stores/AppStore";

import logo from "./logo.svg";
import "./App.css";
import "./main.scss";

function App() {
  React.useEffect(() => {
    const bodyElement = document.querySelector("body");

    if (bodyElement) {
      bodyElement.classList.add(
        `${process.env.REACT_APP_IMAGE_DIR || "default"}-app`
      );
    }
  }, []);

  return (
    <StoreContextProvider>
      <InnerApp />
    </StoreContextProvider>
  );
}

const InnerApp = () => {
  let AppStore = React.useContext(AppStoreContext);

  const menuIsOpen = _.get(AppStore, "state.showMenu", false);

  return (
    <div
      className={cx(
        "App",
        `${process.env.REACT_APP_IMAGE_DIR}`,
        menuIsOpen && "showing-menu"
      )}
    >
      <RouterWrapper />
    </div>
  );
};

export default App;
