export const cardoneSignature = "signature-1.png";
export const tryybLogo = "tryyb-mastery-logo.png";
export const AUDIGY_LOGO = "audigy-logo.png";
export const homeBannerImage = "home-banner-image.png";
export const CV_LOGO = "cv-logo.png";
export const FAKE_PROFILE_IMAGE = "fake-profile-image.png";
export const COURSE_IMAGES = {
  VITAL_PARTS: "img-1.png",
  BUYER_AT_EASE: "img-2.png",
  PURPOSE_OF_GREETING: "img-3.png",
  MISTAKES_TO_AVOID: "img-4.png",
  BEGINNER_CERT: "img-5.png",
  INTERMEDIATE_CERT: "img-6.png",
  ADVANCED_CERT: "img-7.png",
  MASTER_CERT: "img-8.png",
  VITAL_PARTS_1: "img-9.png",
  VITAL_PARTS_2: "img-10.png",
  VITAL_PARTS_3: "img-11.png",
  IMAGE_1: "img-1.png",
  IMAGE_2: "img-2.png",
  IMAGE_3: "img-3.png",
  IMAGE_4: "img-4.png",
  IMAGE_5: "img-5.png",
  IMAGE_6: "img-6.png",
  IMAGE_7: "img-7.png",
  IMAGE_8: "img-8.png",
  IMAGE_9: "img-9.png",
  IMAGE_10: "img-10.png",
  IMAGE_11: "img-11.png",
  IMAGE_12: "img-12.png",
};
// export const COURSE_IMAGES = {
//   VITAL_PARTS: "vital-parts-0.png",
//   BUYER_AT_EASE: "buyer-at-ease.png",
//   PURPOSE_OF_GREETING: "purpose-of-greeting.png",
//   MISTAKES_TO_AVOID: "mistakes-to-avoid.png",
//   BEGINNER_CERT: "beginner-cert.png",
//   INTERMEDIATE_CERT: "intermediate-cert.png",
//   ADVANCED_CERT: "advanced-cert.png",
//   MASTER_CERT: "master-cert.png",
//   VITAL_PARTS_1: "vital-parts.png",
//   VITAL_PARTS_2: "vital-parts-2.png",
//   VITAL_PARTS_3: "vital-parts-3.png",
// };
export const COURSE_VIDEO_POSTERS = {
  SELLING_BASICS: "selling-basics-poster.png",
  MISTAKES_TO_AVOID: "mistakes-to-avoid-poster.png",
  COURSE_1_VIDEO_1: "course-1-video-1-poster.png",
  COURSE_1_VIDEO_2: "course-1-video-2-poster.jpg",
};
