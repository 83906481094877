/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Link } from "react-router-dom";

import { StoreContext as AppStoreContext } from "../../data/stores/AppStore";
import { coursesData } from "../../data/";
import { Menu, Nav } from "../../components/meta/";
import { Icon } from "../../components/common/";
import { parseNumberToFriendlyString, scrollToTop } from "../../data/helpers";

import CourseTask from "./subcomponents/CourseTask";

import "./style.scss";

interface Props {
  courseID?: string;
}

export default (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);

  const [courseData, updateCourseData] = React.useState(() => {
    let course;

    if (props.courseID) {
      course = coursesData.find((cData) => cData.courseID === props.courseID);
    }

    return course;
  });
  const [friendlyUsersCount, updateFriendlyUsersCount] = React.useState<string>(
    () => {
      const usersCount = _.get(courseData, "usersCount", 0);

      return `${parseNumberToFriendlyString(usersCount)}`;
    }
  );

  React.useEffect(() => {
    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          showMenu: false,
        },
        type: "APP_STORE_TOGGLE_NAVMENU",
      });
    }

    scrollToTop();
  }, []);

  React.useEffect(() => {
    scrollToTop();
  }, [props.courseID]);

  if (!courseData) {
    return (
      <main className="course-main-cont">
        <Nav />

        <div className="course-top-cont">
          <Link className="course-top-courses-link" to="/courses">
            <Icon
              className="course-top-courses-link-icon"
              icon="arrow-left"
              size={16}
            />{" "}
            Back to courses
          </Link>
        </div>

        <section className="course-info-cont">
          <h1>Course Not Found.</h1>
        </section>
      </main>
    );
  }

  // const menuIsOpen = _.get(AppStore, "state.showMenu", false);

  return (
    <>
      <Nav />

      <Menu courseID={props.courseID} />

      <main className="course-main-cont">
        <div className="course-top-cont">
          <Link className="course-top-courses-link" to="/courses">
            <Icon
              className="course-top-courses-link-icon"
              icon="arrow-left"
              size={16}
            />{" "}
            Back to courses
          </Link>
        </div>

        <section className="course-info-cont">
          <h1 className="course-info-name title-font">{courseData.name}</h1>
          <p className="course-info-desc">{courseData.description}</p>
          <div className="course-info-inner-cont">
            <span className="course-info-favorites-btn">
              <Icon className="course-info-icon" icon="star" size={14} />
              Add to Favorites
            </span>
            <span className="course-info-users-count">
              <Icon className="course-info-icon" icon="user" size={14} />
              {friendlyUsersCount}
            </span>
          </div>
        </section>

        {courseData.items.map((courseTask, curIdx) => (
          <CourseTask
            courseID={courseData.courseID}
            taskData={courseTask}
            taskIdx={curIdx + 1}
            key={courseTask.taskID}
          />
        ))}
      </main>
    </>
  );
};
