export const DIR_NAME = process.env.REACT_APP_IMAGE_DIR || "cardone";

console.log(
  "process.env.REACT_APP_IMAGE_DIR is ",
  `${process.env.REACT_APP_IMAGE_DIR}`
);

export function getPublicImagePath(imageName: string) {
  if (!imageName) {
    return "";
  }

  return `${process.env.PUBLIC_URL}/${DIR_NAME}/${imageName}`;
}

export function parseNumberToFriendlyString(count: number) {
  if (!count || typeof count !== "number") {
    return "";
  }

  if (count < 1000) {
    return `${count}`;
  }

  return `${Math.floor(count / 1000)}.${Math.floor((count % 1000) / 100)}k`;
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function makeCourseTaskDOMID({
  courseID,
  taskID,
  omitPound,
}: {
  courseID: string;
  taskID: string;
  omitPound?: boolean;
}) {
  return `${!omitPound && "#"}${courseID}-${taskID}`;
}
