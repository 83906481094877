import { SELLING_BASICS, SELLING_IS_A_WAY_OF_LIFE } from "./videoNames";
import { COURSE_IMAGES, COURSE_VIDEO_POSTERS } from "./imageNames";
import { DIR_NAME, getPublicImagePath } from "./helpers";

const isAudigy = DIR_NAME === "audigy";

const items: Mastery.courseData["items"] = [
  {
    name: "",
    description: "",
    completeStatus: "complete",
    taskType: "video",
    videoPath: SELLING_BASICS,
    posterImgPath: `${getPublicImagePath(
      isAudigy
        ? COURSE_VIDEO_POSTERS.COURSE_1_VIDEO_1
        : COURSE_VIDEO_POSTERS.SELLING_BASICS
    )}`,
    taskID: "123",
  },
  {
    name: isAudigy
      ? "Structured Success Course Overview"
      : "What is the Road to the Sale?",
    description: isAudigy
      ? `The Steps to the Sale is your blueprint for a successful career in sales. Without this roadmap you can get lost in the deal. Knowing the Steps to the Sale = Control.`
      : "The Steps to the Sale is your blueprint for a successful career in sales. Without this roadmap you can get lost in the deal. Knowing the Steps to the Sale = Control.",
    completeStatus: "complete",
    taskType: "file",
    fileName: isAudigy
      ? "Road to Success Visual Outline.pdf"
      : "Road to the Sale Visual Outline.pdf",
    filePath: "",
    taskID: "74745656",
  },
  {
    name: "Vital Parts of the Road to the Sale",
    description: `The Steps to the Sale is your blueprint for a successful career in sales. Without this roadmap you can get lost in the deal. Knowing the Steps to the Sale = Control.`,
    completeStatus: "notstarted",
    taskType: "survey",
    questions: [
      {
        questionID: "741852",
        description: "",
        questionText: "Where does the sales process start?",
        answers: [
          {
            answerText: "When you first meet the prospect",
            answerID: "123456",
          },
          {
            answerText: "With your greeting",
            answerID: "456789",
          },
          {
            answerText: "When you get up in the morning",
            answerID: "789123",
          },
        ],
        correctAnswerIDs: ["123456"],
      },
      {
        questionID: "35789951",
        description: "",
        questionText: "Who should start the sales conversation?",
        answers: [
          {
            answerText: "Whoever wants the sale",
            answerID: "75846",
          },
          {
            answerText: "Your best sales person",
            answerID: "97328",
          },
          {
            answerText: "Give them to the new person",
            answerID: "222222",
          },
        ],
        correctAnswerIDs: ["75846"],
      },
    ],
    taskID: "234",
  },
  {
    name: "Mistakes to Avoid",
    description:
      "Mistakes are vital to the process of growth but learning from others mistakes will catapult you ahead of your competition.",
    completeStatus: "notstarted",
    taskType: "video",
    videoPath: SELLING_IS_A_WAY_OF_LIFE,
    posterImgPath: `${getPublicImagePath(
      isAudigy
        ? COURSE_VIDEO_POSTERS.COURSE_1_VIDEO_2
        : COURSE_VIDEO_POSTERS.MISTAKES_TO_AVOID
    )}`,
    taskID: "345",
  },
  {
    name: "Vital Parts of the Road to the Sale",
    description: `The Steps to the Sale is your blueprint for a successful career in sales. Without this roadmap you can get lost in the deal. Knowing the Steps to the Sale = Control.`,
    completeStatus: "notstarted",
    taskType: "survey",
    questions: [
      {
        questionID: "789521",
        description: "",
        questionText:
          "The action of persuading or influencing another to a course of action or to the acceptance of something is the definition of _______.",
        answers: [
          {
            answerText: "selling",
            answerID: "7894",
          },
          {
            answerText: "prospecting",
            answerID: "4561",
          },
          {
            answerText: "manipulation",
            answerID: "12368",
          },
          {
            answerText: "bullying",
            answerID: "757484",
          },
        ],
        correctAnswerIDs: ["7894"],
      },
      {
        questionID: "9638527",
        description: "",
        questionText:
          "It is nsaid that businesses fail because they are undercapitalized, but the truth is it's because ______.",
        answers: [
          {
            answerText:
              "the owner is always on vacation and not paying attention to the business",
            answerID: "757575",
          },
          {
            answerText: "their ideas weren't sold quickly enough",
            answerID: "575757",
          },
          {
            answerText:
              "they never ordered enough products to keep the shelves stocked",
            answerID: "595959",
          },
          {
            answerText:
              "the manager shows poor leadership and no one wnats to work for him/her",
            answerID: "17175151",
          },
        ],
        correctAnswerIDs: ["575757"],
      },
      {
        questionID: "20202020",
        description: "",
        questionText:
          "True or False: Selling impacts every person on this planet.",
        answers: [
          {
            answerText: "True",
            answerID: "111111111111",
          },
          {
            answerText: "False",
            answerID: "000000000000",
          },
        ],
        correctAnswerIDs: ["111111111111"],
      },
    ],
    taskID: "456",
  },
];

export const coursesData: Mastery.courseData[] = [
  {
    name: isAudigy ? "Structured Success" : "The Perfect Sales Process",
    description: isAudigy
      ? `Structured Success is a 15-week program that will take you through three different series of courses on Belief, Operational Effectiveness and Leadership.`
      : `A full knowledge of The Sales Process is the cornerstone of a successful sales career. This step by step breakdown gives you the exact mechanics and precise actions to take.`,
    bannerImagePath: "",
    tileImagePath: "",
    completeStatus: "notstarted",
    enrolledDate: new Date("05/18/2021").toISOString(),
    statusDate: new Date("05/18/2021").toISOString(),
    items,
    usersCount: 7236,
    courseID: "123",
  },
];

// {
//     name: "",
//     description: "",
//     courseID: "",
//     length: "hrs",
//     enrolledCount: "k"
// },

export const categories: Mastery.Category[] = [
  {
    title: isAudigy ? "Most Popular Courses" : "Most Popular Videos",
    categoryID: "cat-123",
    courses: [
      {
        name: isAudigy
          ? "Addressing Caller Concerns"
          : "Vital Parts of the Road to the Sale",
        description:
          "Plaid hexagon ethical small batch tumblr chia ramps freegan butcher subway tile pok pok Bridgetown",
        courseID: "123",
        length: "1.3hrs",
        enrolledCount: "8.3k",
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_1)}`,
      },
      {
        name: isAudigy
          ? "Producing a Powerful Presentation"
          : "Putting the Buyer at Ease",
        description:
          "Chia ramps freegan butcher subway tile pok pok direct trade adaptogen. DIY vinyl ugh lumber ",
        courseID: "234",
        length: "2hrs",
        enrolledCount: "7.8k",
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_2)}`,
      },
      {
        name: isAudigy ? "Crushing COVID" : "Purpose of the Greeting",
        description:
          "Managing time with your prospect is key in securing the sale. Use these tips to keep your customer engaged.",
        courseID: "345",
        length: "2hrs",
        enrolledCount: "12.4k",
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_3)}`,
      },
      {
        name: isAudigy ? "Mistakes to Avoid" : "Mistakes to Avoid",
        description:
          "Use these tips to avoid wasting time. It is crucial to use your time effectively to reach your full potential.",
        courseID: "456",
        length: "2hrs",
        enrolledCount: "12.4k",
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_4)}`,
      },
    ],
  },
  {
    title: isAudigy ? "Structured Success" : "Certification Path",
    categoryID: "cat-234",
    courses: [
      {
        name: isAudigy ? "Structured Success Week 1" : "Beginner Certification",
        description:
          "Use these tips to avoid wasting time. It is crucial to use your time",
        courseID: "567",
        length: "2.4hrs",
        enrolledCount: "14.5k",
        coursesCount: 2,
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_5)}`,
      },
      {
        name: isAudigy
          ? "Structured Success Week 2"
          : "Intermediate Certification",
        description:
          "Use these tips to avoid wasting time. It is crucial to use your time",
        courseID: "678",
        length: "2.4hrs",
        enrolledCount: "14.5k",
        coursesCount: 4,
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_6)}`,
      },
      {
        name: isAudigy ? "Structured Success Week 3" : "Advanced Certification",
        description:
          "Use these tips to avoid wasting time. It is crucial to use your time",
        courseID: "789",
        length: "2.4hrs",
        enrolledCount: "14.5k",
        coursesCount: 3,
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_7)}`,
      },
      {
        name: isAudigy ? "Structured Success Week 4" : "Master Certification",
        description:
          "Use these tips to avoid wasting time. It is crucial to use your time",
        courseID: "890",
        length: "2.4hrs",
        enrolledCount: "14.5k",
        coursesCount: 6,
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_8)}`,
      },
    ],
  },
  {
    title: "Focused on Attitude",
    categoryID: "cat-345",
    courses: [
      {
        name: isAudigy
          ? "Vital Parts to the Road to the Sale"
          : "Vital Parts to the Road to the Sale",
        description:
          "Use these tips to avoid wasting time. It is crucial to use your time effectively to reach your full potential.",
        courseID: "928",
        length: "2hrs",
        enrolledCount: "12.4k",
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_9)}`,
      },
      {
        name: isAudigy
          ? "Vital Parts to the Road to the Sale"
          : "Vital Parts to the Road to the Sale",
        description:
          "Use these tips to avoid wasting time. It is crucial to use your time effectively to reach your full potential.",
        courseID: "5784",
        length: "2hrs",
        enrolledCount: "12.4k",
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_10)}`,
      },
      {
        name: isAudigy
          ? "Vital Parts to the Road to the Sale"
          : "Vital Parts to the Road to the Sale",
        description:
          "Use these tips to avoid wasting time. It is crucial to use your time effectively to reach your full potential.",
        courseID: "1119",
        length: "2hrs",
        enrolledCount: "12.4k",
        imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_11)}`,
      },
    ],
  },
];

if (isAudigy && categories[2] && categories[2].courses) {
  categories[2].courses.push({
    name: "Addressing Caller Concerns",
    description:
      "Use these tips to avoid wasting time. It is crucial to use your time effectively to reach your full potential.",
    courseID: "1119",
    length: "2hrs",
    enrolledCount: "12.4k",
    imagePath: `${getPublicImagePath(COURSE_IMAGES.IMAGE_12)}`,
  });
}
