import { DIR_NAME } from "./helpers";

export const HOME_BG_VIDEO =
  DIR_NAME === "audigy"
    ? "https://www.personaltech.biz/upload/files/audigyshowcase.mp4"
    : "https://www.personaltech.biz/upload/files/CardoneBackgroundVideo.mp4";
export const WELCOME_VIDEO =
  DIR_NAME === "audigy"
    ? "https://www.personaltech.biz/upload/files/audigyshowcase.mp4"
    : "https://www.personaltech.biz/upload/files/Welcome_to_GCU.mp4";
export const SELLING_BASICS =
  DIR_NAME === "audigy"
    ? "https://www.personaltech.biz/upload/files/audigyshowcase.mp4"
    : "https://www.personaltech.biz/upload/files/Selling_Basics_Introduction.mp4";
export const SELLING_IS_A_WAY_OF_LIFE =
  DIR_NAME === "audigy"
    ? "https://www.personaltech.biz/upload/files/audigyshowcase.mp4"
    : "https://www.personaltech.biz/upload/files/Selling_is_a_way_of_life.mp4";
