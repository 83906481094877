/*
 * Component Description
 */
import * as React from "react";

import { categories } from "../../../data/";

import Category from "./Category";

import "./Categories.scss";

interface Props {}

export default (props: Props) => {
  return (
    <section className="home-categories-cont">
      {(categories || []).map((category) => (
        <Category category={category} key={category.categoryID} />
      ))}
    </section>
  );
};
