/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { Link } from "react-router-dom";

import { StoreContext as AppStoreContext } from "../../../data/stores/AppStore";
import {
  AUDIGY_LOGO,
  CV_LOGO,
  FAKE_PROFILE_IMAGE,
  tryybLogo,
} from "../../../data/imageNames";
import { DIR_NAME, getPublicImagePath } from "../../../data/helpers";
import { Icon } from "../../common/";

import "./style.scss";

const LOGO =
  DIR_NAME === "audigy"
    ? getPublicImagePath(AUDIGY_LOGO)
    : getPublicImagePath(CV_LOGO);

interface Props {}

export default (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);

  const menuIsShowing = _.get(AppStore, "state.showMenu", false);
  const isMobile = _.get(AppStore, "state.isMobile", false);

  return (
    <nav className="cm-nav">
      <div className="cm-nav-left">
        <button
          className={cx("cm-nav-menu-btn", menuIsShowing && "menu-is-open")}
          onClick={() => {
            if (AppStore.dispatch) {
              AppStore.dispatch({
                payload: {
                  showMenu: !menuIsShowing,
                },
                type: "APP_STORE_TOGGLE_NAVMENU",
              });
            }
          }}
        >
          <Icon className="" icon="menu" size={20} />
        </button>

        <Link title="link to home page" to="/">
          <img
            alt="Cardone Ventures company Logo"
            className="cm-nav-logo cv-logo"
            src={LOGO}
          />
        </Link>

        <span className="cm-nav-plus">+</span>

        <img
          alt="Tryyb Mastery product Logo"
          className="cm-nav-logo tryyb-logo"
          src={getPublicImagePath(tryybLogo)}
        />
      </div>

      <div className="cm-nav-right">
        {isMobile && (
          <button
            className="cm-nav-text-btn"
            onClick={() => {
              if (AppStore.dispatch) {
                AppStore.dispatch({
                  payload: {
                    showMenu: !menuIsShowing,
                  },
                  type: "APP_STORE_TOGGLE_NAVMENU",
                });
              }
            }}
          >
            {menuIsShowing ? "Hide" : "Show"} Course Overview
          </button>
        )}

        <button className="cm-nav-search-btn">
          <Icon className="" icon="search" size={18} />
        </button>

        <div className="cm-nav-userthumb-cont">
          <img
            className="cm-nav-userthumb"
            src={getPublicImagePath(FAKE_PROFILE_IMAGE)}
          />
        </div>

        <span className="cm-nav-user-points-count">388 pts</span>

        <div className="cm-nav-bell-cont">
          <Icon className="cm-nav-bell" icon="bell" size={20} />
        </div>
      </div>
    </nav>
  );
};
