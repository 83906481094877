/*
 * Component Description
 */
import * as React from "react";

import { Video } from "../../../components/common/";

import { HOME_BG_VIDEO } from "../../../data/videoNames";
import { homeBannerImage } from "../../../data/imageNames";
import { getPublicImagePath } from "../../../data/helpers";

interface Props {}

export default (props: Props) => {
  const videoElement = React.useRef(null);

  React.useEffect(() => {
    let vid = videoElement.current as HTMLVideoElement | null;

    if (vid) {
      vid.playbackRate = 0.85;
    }
  }, [videoElement.current]);

  return (
    <div className="home-main-video-cont">
      <Video
        className="home-main-video"
        controls={false}
        src={HOME_BG_VIDEO}
        poster={getPublicImagePath(homeBannerImage)}
        playsInline={true}
        autoPlay={true}
        muted={true}
        loop={true}
        innerRef={videoElement}
      />

      <div className="home-main-video-overlay" />
    </div>
  );
};
