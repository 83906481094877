import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { Link } from "react-router-dom";

import { Icon } from "../../common/";

import { coursesData } from "../../../data/";
import { makeCourseTaskDOMID } from "../../../data/helpers";

import {
  StoreContext as AppStoreContext,
  AppStoreProps,
} from "../../../data/stores/AppStore";

import "./style.scss";

const HOME_LINKS = {
  GENERAL: [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Browse",
      path: "/",
    },
    {
      label: "Community",
      path: "/",
    },
  ],
  PERSONAL: [
    {
      label: "Incomplete Courses",
      path: "/",
    },
    {
      label: "Certification Path",
      path: "/",
    },
    {
      label: "My Certifications",
      path: "/",
    },
    {
      label: "My Favorites",
      path: "/",
    },
    {
      label: "Profile",
      path: "/",
    },
  ],
};

interface FakeCourseData {
  courseID: string;
  name: string;
  items: {
    name: string;
    taskID: string;
  }[];
}

const fakeCourseModules: FakeCourseData[] = [
  {
    courseID: "ghghghgh575757",
    name: "Foundations of a Flawless Sales Process",
    items: [
      {
        name: "Core Concepts",
        taskID: "g54y489as8a",
      },
      {
        name: "Handling different personalities and their needs",
        taskID: "d232323232s6",
      },
    ],
  },
  {
    courseID: "c4c4c4c4c",
    name: "How to Build the Perfect Greeting",
    items: [
      {
        name: "Purpose of the Greeting",
        taskID: "ghghghghg5656565",
      },
      {
        name: "Your Introduction",
        taskID: "bhbhbbhbh54454545",
      },
      {
        name: "Putting the Buyer at Ease",
        taskID: "5dd5ffghafd",
      },
    ],
  },
  {
    courseID: "vvv777fffg",
    name: "How to Handle Problems in the Greeting",
    items: [
      {
        name: "Identifying the Problem",
        taskID: "asasasas121212",
      },
      {
        name: "Landing on a Solution",
        taskID: "gg333",
      },
      {
        name: "Applying the Solution",
        taskID: "ffffffh",
      },
      {
        name: "Closing Thoughts on the Greeting Process",
        taskID: "ff551bdd",
      },
    ],
  },
];

type SubMenu = "main" | "course";

interface Props {
  courseID?: string;
}

export default (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);

  const [forceMainMenu, updateForceMainMenu] = React.useState<boolean>(false);

  const menuIsShowing = _.get(AppStore, "state.showMenu", false);
  const isMobile = _.get(AppStore, "state.isMobile", false);

  if (!menuIsShowing) {
    return null;
  }

  const isCourseRoute =
    !!props.courseID || /course\//i.test(window.location.pathname);

  function hideMenu() {
    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          showMenu: false,
        },
        type: "APP_STORE_TOGGLE_NAVMENU",
      });
    }
  }

  return (
    <>
      <section className="cm-menu">
        <div className="cm-menu-inner-cont">
          {isCourseRoute && homeLink(AppStore)}

          <hr className="cm-menu-top-bar" />

          {isCourseRoute && (
            <CourseSubMenuToggleButton
              forcingMainMenu={forceMainMenu}
              toggleMainMenu={() => updateForceMainMenu(!forceMainMenu)}
            />
          )}

          {!isCourseRoute || forceMainMenu ? (
            <MainMenuItems
              toggleMainMenu={() => updateForceMainMenu(!forceMainMenu)}
            />
          ) : (
            <CourseMenuItems
              courseID={props.courseID}
              hideMenu={hideMenu}
              toggleMainMenu={() => updateForceMainMenu(!forceMainMenu)}
            />
          )}
        </div>
      </section>

      {menuIsShowing && isMobile && (
        <button className="cm-menu-black-overlay" onClick={hideMenu} />
      )}
    </>
  );
};

function homeLink(AppStore: AppStoreProps) {
  return (
    <Link
      className="cm-menu-courses-link"
      to="/courses"
      onClick={() => {
        if (AppStore.dispatch) {
          AppStore.dispatch({
            payload: {
              showMenu: false,
            },
            type: "APP_STORE_TOGGLE_NAVMENU",
          });
        }
      }}
    >
      <Icon className="cm-menu-courses-link-icon" icon="arrow-left" size={16} />{" "}
      Back to courses
    </Link>
  );
}

interface MainMenuItemsProps {
  toggleMainMenu: () => void;
}

const MainMenuItems = (props: MainMenuItemsProps) => {
  return (
    <>
      <ul className="cm-menu-list">
        {HOME_LINKS.GENERAL.map((linkInfo) => (
          <li className="cm-menu-item" key={linkInfo.label}>
            <Link className="cm-menu-item-link" to={linkInfo.path || "/"}>
              {linkInfo.label}
            </Link>
          </li>
        ))}
      </ul>

      <p className="cm-menu-list-title">My Stuff</p>
      <ul className="cm-menu-list">
        {HOME_LINKS.PERSONAL.map((linkInfo) => (
          <li className="cm-menu-item" key={linkInfo.label}>
            <Link className="cm-menu-item-link" to={linkInfo.path || "/"}>
              {linkInfo.label}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

function findCourseInfo(courseID?: string) {
  if (!courseID) {
    return undefined;
  }

  return coursesData.find((course) => course.courseID === courseID);
}

interface CourseMenuItemsProps {
  courseID?: string;
  toggleMainMenu: () => void;
  hideMenu: () => void;
}

const CourseMenuItems = ({ courseID, hideMenu }: CourseMenuItemsProps) => {
  const [showCourseInfo, updateShowCourseInfo] = React.useState(true);
  const [courseInfo, updateCourseInfo] = React.useState(
    findCourseInfo(courseID)
  );

  React.useEffect(() => {
    updateCourseInfo(findCourseInfo(courseID));
  }, [courseID]);

  if (!courseID || !courseInfo) {
    return null;
  }

  const firstIncompleteIdx = (courseInfo.items || []).findIndex(
    (task) => task.completeStatus !== "complete"
  );

  return (
    <>
      <ul className="cm-menu-list has-toggle-button">
        <button
          className="cm-menu-list-toggle-button"
          onClick={() => updateShowCourseInfo(!showCourseInfo)}
        >
          <Icon
            className="cm-menu-list-toggle-button-icon"
            icon={showCourseInfo ? "angle-down" : "angle-right"}
            size={16}
          />
        </button>

        <li className="cm-menu-item is-course-title">
          <p className="cm-menu-item-subtitle">
            Module 1 - Lessons ({(courseInfo.items || []).length})
          </p>
          <h4 className="cm-menu-item-title">{courseInfo.name}</h4>
        </li>

        {showCourseInfo &&
          (courseInfo.items || []).map((task, curTaskIdx) =>
            task.name ? (
              <li
                className={cx(
                  "cm-menu-item",
                  task.completeStatus === "complete" && "is-complete"
                )}
                key={task.taskID}
              >
                {(task.completeStatus === "complete" ||
                  firstIncompleteIdx === curTaskIdx) && (
                  <Icon
                    className={cx(
                      "cm-menu-item-icon",
                      firstIncompleteIdx === curTaskIdx && "cur-task-icon"
                    )}
                    icon={firstIncompleteIdx === curTaskIdx ? "redo" : "check"}
                    size={firstIncompleteIdx === curTaskIdx ? 18 : 22}
                  />
                )}

                <button
                  className={cx(
                    "cm-menu-item-link is-link-button",
                    curTaskIdx === firstIncompleteIdx && "current-task"
                  )}
                  onClick={() => {
                    hideMenu();

                    scrollTaskIntoView(
                      makeCourseTaskDOMID({
                        courseID: courseInfo.courseID,
                        taskID: task.taskID,
                      })
                    );
                  }}
                >
                  {task.name}
                </button>
              </li>
            ) : null
          )}
      </ul>

      <FakeModule courseInfo={fakeCourseModules[0]} moduleNumber={2} />

      <FakeModule courseInfo={fakeCourseModules[1]} moduleNumber={3} />

      <FakeModule courseInfo={fakeCourseModules[2]} moduleNumber={4} />
    </>
  );
};

interface FakeModuleProps {
  courseInfo: FakeCourseData;
  moduleNumber: number;
}

const FakeModule = ({ courseInfo, moduleNumber }: FakeModuleProps) => {
  const [showCourseInfo, updateShowCourseInfo] = React.useState(false);

  return (
    <ul className="cm-menu-list is-inactive has-toggle-button">
      <button
        className="cm-menu-list-toggle-button"
        onClick={() => updateShowCourseInfo(!showCourseInfo)}
      >
        <Icon
          className="cm-menu-list-toggle-button-icon"
          icon={showCourseInfo ? "angle-down" : "angle-right"}
          size={16}
        />
      </button>

      <li className="cm-menu-item is-course-title">
        <p className="cm-menu-item-subtitle">
          Module {moduleNumber} - Lessons ({courseInfo.items.length})
        </p>
        <h4 className="cm-menu-item-title">{courseInfo.name}</h4>
      </li>

      {showCourseInfo &&
        (courseInfo.items || []).map((task) => (
          <li className="cm-menu-item" key={task.taskID}>
            <button
              className="cm-menu-item-link is-link-button"
              disabled={true}
              onClick={() => {
                // TODO
              }}
            >
              {task.name}
            </button>
          </li>
        ))}
    </ul>
  );
};

interface CourseSubMenuToggleButtonProps {
  forcingMainMenu: boolean;
  toggleMainMenu: () => void;
}

const CourseSubMenuToggleButton = ({
  forcingMainMenu,
  toggleMainMenu,
}: CourseSubMenuToggleButtonProps) => {
  return (
    <div className="cm-menu-courses-submenu-toggle-button-cont">
      <button
        className="cm-menu-courses-submenu-toggle-button"
        onClick={toggleMainMenu}
      >
        {forcingMainMenu ? "Show Course Info" : "Show Main Menu"}
      </button>
    </div>
  );
};

function scrollTaskIntoView(id: string) {
  const targetTaskNode = document.getElementById(id);

  if (targetTaskNode) {
    targetTaskNode.scrollIntoView({ behavior: "smooth" });
  }
}
