/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Props {
  className?: string;
  height?: number;
  progressDecimal: number;
}

function calcProgPercent(progDecimal: number) {
  if (!progDecimal || progDecimal < 0) {
    return 0;
  }

  return Math.floor(progDecimal * 100);
}

export default (props: Props) => {
  const [completeWidthPercent, updateCompleteWidthPercent] = React.useState(
    calcProgPercent(props.progressDecimal)
  );

  React.useEffect(() => {
    updateCompleteWidthPercent(calcProgPercent(props.progressDecimal));
  }, [props.progressDecimal]);

  let height;

  if (props.height && props.height !== 5) {
    height = props.height;
  }

  const style = height
    ? {
        height,
        borderRadius: `${Math.ceil(height / 2)}`,
      }
    : undefined;

  return (
    <div className={cx("cc-progress-bar", props.className)} style={style}>
      {props.progressDecimal && props.progressDecimal > 0 && (
        <div
          className="cc-progress-complete-bar"
          style={{ width: `${completeWidthPercent}%` }}
        />
      )}
    </div>
  );
};
