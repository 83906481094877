import cx from "classnames";

interface Props {
  className?: string;
  innerRef?: any;
  src: string;
  autoPlay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  playsInline?: boolean;
  poster?: string;
  type?: string;
}

export default ({
  className,
  innerRef,
  src,
  autoPlay = false,
  controls = true,
  loop = false,
  muted = false,
  playsInline = false,
  poster,
  type = "video/mp4",
}: Props) => {
  if (!src) {
    return null;
  }

  return (
    <video
      className={cx("cc-video", className)}
      crossOrigin="anonymous"
      autoPlay={autoPlay}
      playsInline={playsInline}
      controls={controls}
      controlsList="nodownload"
      loop={loop}
      muted={muted}
      poster={poster}
      ref={innerRef}
    >
      <source src={src} type={type}></source>
    </video>
  );
};
