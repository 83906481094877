/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as Icons from "./icons/";

import { iconType } from "./typings/";

import "./style.scss";

export interface IconProps {
  buttonProps?: {
    className?: string;
    disabled?: boolean;
    name?: string;
    style?: React.CSSProperties;
  };
  color?: string;
  className?: string;
  hasDropShadow?: boolean;
  gradient?: {
    offsetPercent: number;
    color: string;
  }[];
  style?: React.CSSProperties;
  icon: iconType;
  isButton?: boolean;
  title?: string;
  onClick?: () => void;
  size: number;
}

export default (props: IconProps) => {
  const [gradientKey] = React.useState(() => {
    return Array.from({ length: 10 }, () =>
      String.fromCharCode(Math.round(Math.random() * 42) + 48)
    ).join("");
  });

  if (props.isButton) {
    return (
      <button
        name={props.icon}
        {...(props.buttonProps || {})}
        className={cx(
          "cc-icon-button",
          props.buttonProps && props.buttonProps.className
        )}
        onClick={props.onClick}
        title={props.buttonProps ? props.buttonProps.name : undefined}
      >
        {renderIcon(props, gradientKey)}
      </button>
    );
  }

  return renderIcon(props, gradientKey);
};

const renderIcon = (
  {
    color = "#223958",
    className,
    gradient,
    hasDropShadow,
    icon,
    size,
    style,
    title,
  }: IconProps,
  gradientKey: string
) => {
  const iconProps = {
    className: cx(
      "cc-icon",
      hasDropShadow && "cc-icon-has-drop-shadow",
      className
    ),
    color,
    gradient,
    gradientKey,
    size,
    style,
    title,
  };

  switch (icon) {
    case "angle-down":
      return <Icons.AngleDown {...iconProps} />;
    case "angle-left":
      return <Icons.AngleLeft {...iconProps} />;
    case "angle-right":
      return <Icons.AngleRight {...iconProps} />;
    case "angle-up":
      return <Icons.AngleUp {...iconProps} />;
    case "arrow-left":
      return <Icons.ArrowLeft {...iconProps} />;
    case "arrow-from-left":
      return <Icons.ArrowFromLeft {...iconProps} />;
    case "arrow-alt-from-top":
      return <Icons.AngleAltFromTop {...iconProps} />;
    case "ban":
      return <Icons.Ban {...iconProps} />;
    case "bars":
    case "menu":
      return <Icons.Bars {...iconProps} />;
    case "bell":
      return <Icons.Bell {...iconProps} />;
    case "check":
      return <Icons.Check {...iconProps} />;
    case "circle":
      return <Icons.Circle {...iconProps} />;
    case "clone":
      return <Icons.Clone {...iconProps} />;
    case "close":
      return <Icons.Close {...iconProps} />;
    case "cog":
      return <Icons.Cog {...iconProps} />;
    case "comment-alt-exclamation":
      return <Icons.CommentAltExclamation {...iconProps} />;
    case "copy":
      return <Icons.Copy {...iconProps} />;
    case "download":
      return <Icons.Download {...iconProps} />;
    case "external-link":
      return <Icons.ExternalLink {...iconProps} />;
    case "file":
    case "file-alt":
      return <Icons.FileAlt {...iconProps} />;
    case "filter":
      return <Icons.Filter {...iconProps} />;
    case "home-lg-alt-solid":
      return <Icons.HomeLgAltSolid {...iconProps} />;
    case "home-lg-alt":
      return <Icons.HomeLgAlt {...iconProps} />;
    case "id-card":
      return <Icons.IDCard {...iconProps} />;
    case "info-circle":
      return <Icons.InfoCircle {...iconProps} />;
    case "list-ul":
      return <Icons.ListUL {...iconProps} />;
    case "lock-alt":
      return <Icons.LockAlt {...iconProps} />;
    case "minus":
      return <Icons.Minus {...iconProps} />;
    case "play":
    case "play-circle":
      return <Icons.PlayCircle {...iconProps} />;
    case "plus":
      return <Icons.Plus {...iconProps} />;
    case "question":
    case "question-circle":
      return <Icons.QuestionCircle {...iconProps} />;
    case "redo":
      return <Icons.Redo {...iconProps} />;
    case "route":
      return <Icons.Route {...iconProps} />;
    case "search":
      return <Icons.Search {...iconProps} />;
    case "sign-out":
      return <Icons.SignOut {...iconProps} />;
    case "slash":
      return <Icons.Slash {...iconProps} />;
    case "square":
      return <Icons.Square {...iconProps} />;
    case "star":
      return <Icons.Star {...iconProps} />;
    case "trash":
    case "trash-alt":
      return <Icons.TrashAlt {...iconProps} />;
    case "undo-alt":
      return <Icons.UndoAlt {...iconProps} />;
    case "user-alt-solid":
      return <Icons.UserAltSolid {...iconProps} />;
    case "user":
    case "user-alt":
      return <Icons.UserAlt {...iconProps} />;
    case "users-class-solid":
      return <Icons.UsersClassSolid {...iconProps} />;
    case "users-class":
      return <Icons.UsersClass {...iconProps} />;
    case "users-solid":
      return <Icons.UsersSolid {...iconProps} />;
    case "users":
      return <Icons.Users {...iconProps} />;
    case "window-close":
      return <Icons.WindowClose {...iconProps} />;
    default:
      return <span className={className} style={style}></span>;
  }
};
