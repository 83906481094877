/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Props {
  className?: string;
  onClick: () => void;
  icon?: string;
  type: "red" | "blue";
  value: string;
}

export default (props: Props) => {
  return (
    <button
      className={cx(
        "cc-button",
        props.type === "red" && "red-btn",
        props.className
      )}
      onClick={props.onClick}
    >
      {props.value}
    </button>
  );
};
