/*
 * Component Description
 */
import * as React from "react";
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";

import { Button, Icon } from "../../../components/common/";
import { DIR_NAME, getPublicImagePath } from "../../../data/helpers";
import { cardoneSignature } from "../../../data/imageNames";
import WelcomeVideo from "./WelcomeVideo";

import "./Banner.scss";

const isCardone = DIR_NAME === "cardone";
const isAudigy = DIR_NAME === "audigy";

const CARDONE_NAME = "Cardone-U!";
const CARDONE_QUOTE = `Success lies in the gap between your current reality (income,
  production, happiness) and what your true potential is. Spend this
  month focusing on closing that gap between where you are now and where
  you could be.`;
const CARDONE_FROM = `Grant Cardone`;

const AUDIGY_NAME = "Audigy University";
const AUDIGY_QUOTE = `Get everything you need to become more efficient, more profitable, and a more patient-focused practice.`;
const AUDIGY_FROM = `Mason Walker`;

interface Props {}

export default (props: Props) => {
  const [videoModalIsOpen, updateVideoModalIsOpen] = React.useState(false);

  return (
    <header className="home-banner-cont">
      <section className="home-banner-inner-cont">
        <h1 className="home-banner-title">
          Welcome to {isAudigy ? AUDIGY_NAME : CARDONE_NAME}
        </h1>
        <p className="home-banner-quote">
          “{isAudigy ? AUDIGY_QUOTE : CARDONE_QUOTE}”
        </p>
        <p className="home-banner-from">
          – {isAudigy ? AUDIGY_FROM : CARDONE_FROM}
        </p>
        {isCardone && (
          <img
            className="home-banner-signature-img"
            src={getPublicImagePath(cardoneSignature)}
          />
        )}

        <Button
          className="home-banner-play-button"
          type="red"
          onClick={() => updateVideoModalIsOpen(true)}
          value="Play Welcome Video"
        />

        {/* <ProgressBar
          className="home-banner-progress-bar"
          progressDecimal={0.8}
        /> */}
      </section>

      <DialogOverlay
        className="home-banner-modal-overlay"
        isOpen={videoModalIsOpen}
        onDismiss={() => updateVideoModalIsOpen(false)}
      >
        <DialogContent className="home-banner-modal-content">
          <section className="home-banner-modal-inner-cont">
            <button
              className="home-banner-modal-close-btn"
              onClick={() => updateVideoModalIsOpen(false)}
              aria-title="Close Modal"
              title="Close Modal"
            >
              <Icon
                className="home-banner-modal-close-btn-icon"
                icon="close"
                size={28}
              />
            </button>

            <WelcomeVideo className="home-banner-welcome-video" />
          </section>
        </DialogContent>
      </DialogOverlay>
    </header>
  );
};
