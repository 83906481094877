/*
 * Component Description
 */
import { Video } from "../../../components/common/";
import { WELCOME_VIDEO } from "../../../data/videoNames";

interface Props {
  className?: string;
}

export default (props: Props) => {
  return (
    <Video
      autoPlay={true}
      className={props.className}
      src={WELCOME_VIDEO}
      playsInline={true}
    />
  );
};
