/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./SurveyTask.scss";

interface Props {
  onValueChange: (questionID: string, answerID: string) => void;
  taskData: Mastery.SurveyTask;
}

export default ({ onValueChange, taskData }: Props) => {
  //   const [selectedAnswerID, updateSelectedAnswerID] =
  //     React.useState<string | null>(null);

  return (
    <section className="course-survey-task">
      {(taskData.questions || []).map((questionData, curIdx) => (
        <Question
          curIdx={curIdx}
          onValueChange={onValueChange}
          questionData={questionData}
          taskData={taskData}
          key={questionData.questionID}
        />
      ))}
    </section>
  );
};

interface QuestionProps {
  curIdx: number;
  onValueChange: (questionID: string, answerID: string) => void;
  questionData: Mastery.QuestionData;
  taskData: Mastery.SurveyTask;
}

function Question({
  curIdx,
  onValueChange,
  questionData,
  taskData,
}: QuestionProps) {
  const [correctAnswerIDs] = React.useState(() => {
    const ids = (questionData.correctAnswerIDs || []).reduce(
      (accum: { [x: string]: boolean }, answerID) => {
        accum[answerID] = true;

        return accum;
      },
      {}
    );

    return ids;
  });
  const [selectedAnswerID, updateSelectedAnswerID] =
    React.useState<string | null>(null);

  const baseAnimationTime =
    (window.innerWidth < 800 ? 380 : 480) + curIdx * 200;

  return (
    <div className="course-survey-task-question" key={questionData.questionID}>
      <p
        className="course-survey-task-question-number"
        style={{ animationDuration: `${baseAnimationTime}ms` }}
      >
        Question {curIdx + 1}
      </p>
      <h3
        className="course-survey-task-question-text"
        style={{ animationDuration: `${baseAnimationTime}ms` }}
      >
        {questionData.questionText}
      </h3>

      <div className="course-survey-task-question-answers-cont">
        {(questionData.answers || []).map((answer, answerIdx) => (
          <div
            className="course-survey-task-question-answer-wrapper"
            key={answer.answerID}
            style={{
              animationDuration: `${
                baseAnimationTime + (answerIdx * 40 + 40)
              }ms`,
            }}
          >
            <div
              className={cx(
                "course-survey-task-question-answer-cont",
                answer.answerID === selectedAnswerID && "is-checked",
                answer.answerID === selectedAnswerID &&
                  correctAnswerIDs[selectedAnswerID] &&
                  "correct-answer",
                answer.answerID === selectedAnswerID &&
                  !correctAnswerIDs[selectedAnswerID] &&
                  "incorrect-answer"
              )}
              onClick={() => {
                updateSelectedAnswerID(answer.answerID);
                onValueChange(questionData.questionID, answer.answerID);
              }}
              role="button"
            >
              <input
                className="course-survey-task-question-answer-inp"
                checked={answer.answerID === selectedAnswerID}
                type="radio"
                id={`answer-${taskData.taskID}::${questionData.questionID}::${answer.answerID}`}
                name={`question-${taskData.taskID}::${questionData.questionID}`}
                onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
                  const isChecked = (e.target as HTMLInputElement).checked;

                  if (isChecked) {
                    updateSelectedAnswerID(answer.answerID);
                    onValueChange(questionData.questionID, answer.answerID);
                  }
                }}
                value={answer.answerText}
              />
              <label
                className="course-survey-task-question-answer-label"
                htmlFor={`answer-${taskData.taskID}::${questionData.questionID}::${answer.answerID}`}
              >
                {answer.answerText}
              </label>
            </div>

            {selectedAnswerID && answer.answerID === selectedAnswerID && (
              <p
                className={cx(
                  "course-survey-task-question-answer-marker",
                  `${
                    correctAnswerIDs[selectedAnswerID] ? "" : "in"
                  }correct-answer`
                )}
              >
                {correctAnswerIDs[selectedAnswerID] ? "Correct" : "Incorrect"}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
