/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Link } from "react-router-dom";

import { Icon } from "../../../components/common/";

import "./Category.scss";

interface Props {
  category: Mastery.Category;
}

export default ({ category }: Props) => {
  return (
    <section className="home-categories-category-cont">
      <h5 className="home-categories-category-title">{category.title}</h5>

      <ul className="home-categories-category-courses-cont">
        {(category.courses || []).map((course) => (
          <CategoryCourse categoryCourse={course} key={course.courseID} />
        ))}
      </ul>
    </section>
  );
};

interface CategoryCourseProps {
  categoryCourse: Mastery.CategoryCourse;
}

const CategoryCourse = ({ categoryCourse }: CategoryCourseProps) => {
  const enrolledCount = _.get(categoryCourse, "enrolledCount", "");
  const timeLength = _.get(categoryCourse, "length", "");

  return (
    <Link
      // to={`/course/${categoryCourse.courseID}`}
      to={`/course/123`}
      key={categoryCourse.courseID}
    >
      <li className="home-categories-category-course">
        <div className="home-categories-category-course-image-cont">
          {categoryCourse.imagePath && (
            <img
              className="home-categories-category-course-image"
              src={categoryCourse.imagePath}
            />
          )}

          <Icon
            className="home-category-course-play-icon"
            icon="play"
            size={48}
          />

          <div className="home-category-course-info-cont">
            <div className="home-category-course-info-item-cont">
              <Icon
                className="home-category-course-info-item-icon"
                icon="wifi"
                size={10}
              />

              <span className="home-category-course-info-item-text">
                {timeLength}
              </span>
            </div>

            <div className="home-category-course-info-item-cont">
              <Icon
                className="home-category-course-info-item-icon"
                icon="user-alt"
                size={10}
              />

              <span className="home-category-course-info-item-text">
                {enrolledCount}
              </span>
            </div>
          </div>
        </div>
        <h6 className="home-categories-category-course-name">
          {categoryCourse.name}
        </h6>
        <p className="home-categories-category-course-desc">
          <span className="home-categories-category-course-desc-text">
            {categoryCourse.description}
          </span>
        </p>
      </li>
    </Link>
  );
};
