/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { MOBILE_WIDTH_BREAKPOINT } from "../constants/";

function isMobileSize(): boolean {
  return window && window.innerWidth
    ? window.innerWidth <= MOBILE_WIDTH_BREAKPOINT
    : false;
}

export type AppStoreDispatch = React.Dispatch<
  DataStorage.StoreAction<AppStoreActionType>
>;

export interface AppStoreProps {
  state?: AppStoreState;
  dispatch?: AppStoreDispatch;
}

export interface AppStoreState {
  isMobile: boolean;
  showMenu: boolean;
}

type AppStoreActionType = "APP_STORE_TOGGLE_NAVMENU";

let StoreContext: React.Context<AppStoreProps> = React.createContext({});

let initialState: AppStoreState = {
  isMobile: isMobileSize(),
  showMenu: false,
};

let reducer = (
  state: AppStoreState,
  action: DataStorage.StoreAction<AppStoreActionType>
) => {
  console.log(action);

  switch (action.type) {
    case "APP_STORE_TOGGLE_NAVMENU":
      return {
        ...state,
        showMenu:
          action.payload.showMenu !== undefined
            ? !!action.payload.showMenu
            : !state.showMenu,
      };
    default:
      return state;
  }
};

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
